<template>
    <section v-if="listCategoriesMenu" class="py-10 md:py-20 bg-white">
        <Container>
            <div
                    class="text-[32px] md:text-4xl xl:text-7xl font-extralight tracking-wide max-w-4xl mb-4 md:mb-12"
            >
                Наши услуги
            </div>

            <client-only>
                <TabGroup>
                    <TabList class="mb-10 flex justify-between items-center sm:block">
                        <Tab
                                v-slot="{ selected }"
                                v-for="category in listCategoriesMenu"
                                :key="category.id"
                                :ref="`tab_${category.id}`"
                        >
                            <button
                                    :class="[
                                        selected
                                          ? 'border-b-2 border-pink-600/40'
                                          : 'bg-white text-black border-b-2 border-transparent',
                                        'py-3 px-2 md:py-5 md:px-8 focus:outline-none font-medium text-sm md:text-sm uppercase mr-0 md:mr-10',
                                      ]"
                            >
                                {{ category.attributes.title }}
                            </button>
                        </Tab>
                    </TabList>
                    <TabPanels class="mb-5 sm:mb-14">
                        <TabPanel class="grid grid-cols-2 xl:grid-cols-3 gap-6"
                                  v-for="category in listCategoriesMenu"
                                  :key="category.id">
                            <CardService
                                    v-for="(service, idx) in category.attributes.menu"
                                    :service="service"
                                    :idx="idx"
                                    :key="service.id"
                            />
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </client-only>
            <div
                    class="block md:grid grid-cols-2 max-w-5xl items-start mb-4 text-center md:text-left"
            >
                <NuxtLink
                        to="/services"
                        class="inline-flex mr-auto text-white uppercase justify-center bg-pink-600 rounded-full md:text-base text-sm py-4 md:py-6 md:px-10 px-8 font-light md:tracking-wider"
                >Смотреть все услуги
                </NuxtLink>

                <div class="text-sm md:text-base pt-5 max-w-sm text-left">
                    Клиника Фомина. Дети – это консультации всех детских специалистов,
                    включая закрепленного за ребенком педиатра
                </div>
            </div>
        </Container>
    </section>
</template>
<script>
import {TabGroup, TabList, Tab, TabPanels, TabPanel} from "@headlessui/vue";
import {useServices} from "~/store/services";

export default {
    components: {
        TabGroup,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
    },
    async setup() {
        const servicesStore = useServices();
        if (!servicesStore.listCategoriesMenu?.length)
            await servicesStore.fetchCategoryMenu();
        const listCategoriesMenu = servicesStore.listCategoriesMenu;

        return {listCategoriesMenu};
    },
};
</script>
