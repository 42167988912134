<template>
    <section class="" v-if="clinic?.attributes">
        <div v-if="version !== 'b'">
            <SectionsMobileHomeHero :clinic="clinic" class="block md:hidden"/>
        </div>

        <Container
            class="z-0 md:block py-5 pb-0 lg:pb-12"
            :class="{ hidden: version !== 'b' }"
        >
            <!-- class hidden for other main screen -->
            <div
                class="text-[32px] leading-10 md:text-6xl xl:text-8xl font-light xl:max-w-4xl max-w-2xl mb-5 md:mb-8"
            >
                Бережная забота о маленьких гениях
            </div>
            <h2
                class="text-xl md:text-3xl font-light max-w-lg leading-8 md:leading-9"
            >
                Комфортное наблюдение за здоровьем ребенка в сети клиник нового поколения
            </h2>

            <div
                class="block md:grid grid-cols-2 py-6 md:py-4 max-w-3xl xl:max-w-5xl"
            >
                <div class="space-y-12 md:space-y-9 pb-0 md:py-6">
                    <div class="flex">
                        <div
                            @click="slotsStore.openDialog('all')"
                            class="cursor-pointer inline-flex text-white md:text-base items-center text-xs uppercase justify-center bg-pink-600 rounded-full py-4 md:py-6 md:px-10 px-4 mb md:mb-0 font-medium md:tracking-wider"
                        >
                            Записаться
                            <span class="hidden md:inline-block">&nbsp;на прием</span>
                        </div>
                        <div
                            @click="slotsStore.openHome()"
                            v-if="clinic?.attributes?.home"
                            class="inline-flex ml-2 md:hidden md:text-base items-center text-xs uppercase justify-center border border-pink-600 rounded-full py-4 md:py-6 md:px-10 px-4 mb md:mb-0 font-medium md:tracking-wider text-pink-600 bg-white hover:text-black transition-colors"
                        >
                            Вызвать на дом
                        </div>
                    </div>

                    <div
                        class="text-base md:text-lg md:text-3xl max-w-sm"
                        v-show="clinic?.attributes"
                    >
                        <a
                            :href="`tel:${clinic?.attributes?.phone}`"
                            class="md:hidden text-3xl font-light hover:text-pink-500 mb-4"
                        >{{ clinic?.attributes?.phone }}</a
                        >
                        <div
                            class="md:hidden text-xl md:text-base text-black mt-6 font-light"
                        >
                            <NuxtLink
                                to="contacts"
                                v-html="`${clinic?.attributes?.address}`"
                            ></NuxtLink>
                        </div>
                        <div
                            class="text-base leading-6 mt-5 md:mt-12 max-w-full md:max-w-[18.75rem] font-medium"
                        >
                            <div class="text-black/40">Время работы</div>
                            <HoursList :schedule="clinic?.attributes"/>
                        </div>
                    </div>
                </div>
                <a href="https://ai.fomin-clinic.ru" target="_blank">
                    <div
                        class="flex items-center md:block gap-6 max-w-full md:max-w-xs mt-4 md:mt-0 sm:pr-10 xl:pr-5"
                    >
                        <svg
                            width="120"
                            height="120"
                            viewBox="0 0 120 120"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sm:mb-6 min-w-[80px] w-20 h-20 md:w-32 md:h-32 order-2 md:-order-1"
                        >
                            <path
                                d="M119.734 60.0613C119.697 93.0524 92.9299 119.766 59.9388 119.734C26.9477 119.697 0.229002 92.9246 0.266274 59.9388"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M0.266235 59.9388C0.303507 26.9477 27.0702 0.229002 60.0612 0.266274C93.0523 0.303546 119.766 27.0702 119.734 60.0613"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M43.6456 111.64L45.9139 102.919C46.3984 103.047 48.0118 103.435 48.262 103.505C50.6421 104.122 51.0574 105.496 50.69 106.902C50.3386 108.259 49.1779 108.877 48.1555 108.877L50.099 113.318L48.8051 112.982L47.0321 108.797L45.6104 108.43L44.7052 111.917L43.6456 111.64ZM46.7126 104.186L45.8713 107.423L47.1545 107.759C48.1609 108.02 49.295 107.652 49.5666 106.614C49.8008 105.72 49.4867 104.878 47.9426 104.479C47.6657 104.41 47.1279 104.282 46.7126 104.186Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M55.855 104.932L60.9399 105.134L60.9027 106.161L56.9092 106.007L56.7974 108.914L60.4022 109.053L60.3596 110.086L56.7548 109.942L56.6377 112.956L60.7589 113.121L60.7163 114.148L55.5035 113.946L55.855 104.932Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M65.0397 104.91L65.1941 104.884C65.7798 104.772 66.7329 104.553 67.4517 104.42C70.9979 103.755 72.8136 105.523 73.2502 107.839C73.7241 110.357 72.4142 112.732 69.0331 113.366C68.4368 113.477 67.5955 113.611 66.85 113.749L66.7116 113.776L65.0397 104.91ZM66.3069 105.72L67.5955 112.556C68.1812 112.46 68.7296 112.37 68.8787 112.338C71.4132 111.859 72.4994 110.107 72.1107 108.046C71.7486 106.124 70.1885 104.947 67.6221 105.432C67.0843 105.533 66.7435 105.624 66.3069 105.72Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M80.6887 100.145C81.1147 99.8784 82.0678 99.2661 82.7866 98.8401C84.6342 97.7433 85.779 98.2012 86.418 99.2767C86.9984 100.251 86.7481 101.178 86.1517 101.859L86.1677 101.88C87.222 101.465 88.2337 101.705 88.862 102.764C89.7938 104.34 88.9897 105.73 87.3817 106.683C86.6949 107.088 85.7417 107.626 85.2732 107.876L80.6887 100.145ZM82.1477 100.464L83.6332 102.967L84.8738 102.232C85.6938 101.747 86.0985 100.89 85.598 100.049C85.1188 99.2395 84.3201 99.133 83.2871 99.74C82.8825 99.9849 82.4192 100.288 82.1477 100.464ZM84.1284 103.797L85.6938 106.433C85.9601 106.305 86.4765 106.028 86.9132 105.767C88.0579 105.091 88.452 104.223 87.8876 103.27C87.403 102.45 86.4978 102.392 85.3797 103.052L84.1284 103.797Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M94.6072 96.3856L97.2429 99.2129L96.4442 99.9583L93.8085 97.131L87.9622 95.5389L88.8833 94.6817L93.537 95.9756L92.5892 91.2314L93.4624 90.4114L94.6072 96.3856Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M7.88574 74.7304L16.4477 71.8765C16.6074 72.3557 17.0387 73.5697 17.2197 74.1075C18.0344 76.5514 16.9535 77.7175 15.6224 78.1595C14.2273 78.6227 12.6033 78.0636 11.9058 75.9764C11.7887 75.6356 11.6289 75.1138 11.5278 74.6772L8.23716 75.7741L7.88574 74.7304ZM15.8194 73.2396L12.5181 74.3417C12.6193 74.7677 12.7311 75.183 12.8749 75.609C13.2955 76.8709 14.1954 77.4087 15.2709 77.052C16.2826 76.7112 16.7299 75.8859 16.2773 74.5281C16.1601 74.1873 15.9631 73.6389 15.8194 73.2396Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M21.8733 83.8036C23.263 86.0186 22.7093 88.7075 20.3824 90.1611C18.1142 91.5828 15.3986 90.9385 14.0142 88.7235C12.6298 86.5085 13.1783 83.8249 15.5051 82.366C17.7201 80.9762 20.4517 81.53 21.8733 83.8036ZM14.8875 88.1751C15.979 89.9215 18.0449 90.257 19.7648 89.1761C21.5432 88.0633 22.0224 85.9867 21.0001 84.3467C19.9618 82.6854 17.9012 82.2328 16.1228 83.3457C14.3816 84.4319 13.8598 86.5351 14.8875 88.1751Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M26.6442 102.717L25.8987 102.051L28.8805 94.9799L22.2408 98.787L21.49 98.1161L25.4568 89.5648L26.378 90.3848L23.1672 97.147L29.6898 93.3346L30.4779 94.0428L27.4908 100.997L33.8004 97.0032L34.5991 97.7167L26.6442 102.717Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M37.5117 99.1331L42.0429 101.444L41.5743 102.365L38.0175 100.549L36.6917 103.142L39.9077 104.782L39.4392 105.698L36.2284 104.058L34.8547 106.747L38.5287 108.616L38.0601 109.537L33.4117 107.163L37.5117 99.1331Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M94.6072 96.3856L97.2429 99.2129L96.4442 99.9583L93.8085 97.131L87.9622 95.5389L88.8833 94.6817L93.537 95.9756L92.5892 91.2314L93.4624 90.4114L94.6072 96.3856Z"
                                stroke="#222222"
                                stroke-width="0.1"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                            <path
                                d="M99.692 81.2477L100.129 80.4171L109.963 81.1839L109.42 82.2221L106.465 81.9825L104.559 85.6192L106.449 87.9035L105.938 88.8832L99.692 81.2477ZM103.845 84.7566L105.347 81.8814L101.183 81.5353L103.845 84.7566Z"
                                fill="#D6006D"
                            ></path>
                            <path
                                d="M103.368 72.3254L103.057 73.3717L111.693 75.9384L112.004 74.8921L103.368 72.3254Z"
                                fill="#D6006D"
                            ></path>
                            <path
                                d="M103.368 72.3254L103.057 73.3717L111.693 75.9384L112.004 74.8921L103.368 72.3254Z"
                                fill="#D6006D"
                            ></path>
                            <path
                                d="M99.692 81.2477L100.129 80.4171L109.963 81.1839L109.42 82.2221L106.465 81.9825L104.559 85.6192L106.449 87.9035L105.938 88.8832L99.692 81.2477ZM103.845 84.7566L105.347 81.8814L101.183 81.5353L103.845 84.7566Z"
                                stroke="#D6006D"
                                stroke-width="0.1"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                            <path
                                d="M20.4996 24.4878L21.2983 23.7211L26.9477 24.7061L26.0798 19.1206L26.8306 18.3965L33.0869 24.8978L32.2936 25.6592L27.2405 20.3985L28.0446 25.8509L27.9221 25.9627L22.5123 25.0043L27.5387 30.233L26.7507 30.9891L20.4996 24.4878Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M32.7462 14.3924L37.2029 11.9431L37.6981 12.843L34.1998 14.7651L35.6002 17.3156L38.763 15.5851L39.2582 16.485L36.1007 18.2208L37.549 20.8618L41.159 18.8757L41.6542 19.7809L37.0857 22.2941L32.7462 14.3924Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M43.3101 9.4086L43.4592 9.366C44.0342 9.21691 44.9713 8.92939 45.6795 8.74303C49.1671 7.82187 51.1106 9.45652 51.7122 11.7408C52.3672 14.222 51.2277 16.682 47.8998 17.5605C47.3141 17.7149 46.4835 17.9066 45.7487 18.1036L45.6103 18.1409L43.3101 9.4086ZM44.6306 10.1274L46.4036 16.8577C46.984 16.7192 47.5218 16.5915 47.6709 16.5489C50.1628 15.8886 51.1212 14.0676 50.5834 12.0336C50.0829 10.1381 48.443 9.07847 45.9138 9.74937C45.392 9.88249 45.0618 9.99963 44.6306 10.1274Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M57.9559 6.84873L56.8604 6.90149L57.2945 15.9158L58.39 15.8631L57.9559 6.84873Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M70.3057 16.0004C69.6188 16.5222 68.4527 16.7406 67.3452 16.5755C64.6723 16.1815 63.0909 14.0996 63.5008 11.3201C63.8789 8.75901 65.833 6.9806 68.6231 7.39592C69.7679 7.56631 70.6517 8.01889 71.2055 8.66317L70.5879 9.40328C70.0661 8.8868 69.4111 8.55135 68.4793 8.41824C66.5465 8.13071 64.9545 9.38199 64.6403 11.4958C64.3262 13.6203 65.5295 15.271 67.5901 15.5745C68.4421 15.7023 69.2993 15.5265 69.9862 15.1219L70.3057 16.0004Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M77.192 9.6961L74.137 18.1829L75.169 18.5544L78.224 10.0676L77.192 9.6961Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M85.5552 24.0352L84.5702 15.0313L80.7897 21.0321L79.9165 20.4784L84.7299 12.8483L85.47 13.3168L86.4391 22.3367L90.2355 16.3199L91.1088 16.8683L86.2954 24.4984L85.5552 24.0352Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M96.4282 21.4581L99.9318 25.1481L99.1863 25.8562L96.4335 22.9597L94.3197 24.967L96.8062 27.5867L96.0608 28.2949L93.5742 25.6805L91.3858 27.7571L94.2238 30.7442L93.4784 31.4524L89.8843 27.6719L96.4282 21.4581Z"
                                fill="#222222"
                            ></path>
                            <path
                                d="M59.8217 47.8361H63.6394C66.2218 47.8361 68.309 45.7435 68.309 43.1664V42.405"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M70.0076 40.7065C70.0076 41.6436 69.2461 42.405 68.309 42.405C67.3719 42.405 66.6105 41.6436 66.6105 40.7065C66.6105 39.7693 67.3719 39.0079 68.309 39.0079C69.2461 39.0079 70.0076 39.7693 70.0076 40.7065Z"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M70.0076 73.0479C70.0076 73.985 69.2461 74.7465 68.309 74.7465C67.3719 74.7465 66.6105 73.985 66.6105 73.0479C66.6105 72.1108 67.3719 71.3494 68.309 71.3494C69.2461 71.3494 70.0076 72.1108 70.0076 73.0479Z"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M79.8688 66.5785C79.8688 67.5157 79.1073 68.2771 78.1702 68.2771C77.2331 68.2771 76.4717 67.5157 76.4717 66.5785C76.4717 65.6414 77.2331 64.88 78.1702 64.88C79.1073 64.88 79.8688 65.6414 79.8688 66.5785Z"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M59.8217 55.514H64.4913C66.1206 55.514 67.6861 56.1636 68.8415 57.3137L77.2277 65.7"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M59.8217 73.048H66.6105"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M83.6012 51.3023C83.6012 52.2394 82.8398 53.0008 81.9026 53.0008C80.9655 53.0008 80.2041 52.2394 80.2041 51.3023C80.2041 50.3652 80.9655 49.6038 81.9026 49.6038C82.8451 49.6091 83.6012 50.3652 83.6012 51.3023Z"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M59.4808 64.2731H64.6776C66.2537 64.2731 67.7765 63.7033 68.9692 62.6704L80.5342 52.6176"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M53.7091 47.6656C51.3663 47.6656 49.4654 45.7648 49.4654 43.4219V42.4049C49.4654 40.0621 51.3663 38.1613 53.7091 38.1613H55.2372C57.5801 38.1613 59.4809 40.0621 59.4809 42.4049V77.5472C59.4809 80.3106 57.2393 82.5576 54.4705 82.5576C51.7071 82.5576 49.4601 80.3159 49.4601 77.5472V76.445"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M49.4654 43.4166H46.6647C43.6776 43.4166 41.2603 45.834 41.2603 48.8211C41.2603 51.8082 43.6829 54.2255 46.6647 54.2255H50.0032C51.8615 54.2255 53.3683 55.7324 53.3683 57.5907V61.2167"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M49.4653 61.9196C48.5495 64.0867 46.4037 65.6042 43.9011 65.6042C40.5679 65.6042 37.863 62.8993 37.863 59.5661C37.863 56.233 40.5679 53.5281 43.9011 53.5281"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M44.487 65.6095C42.847 66.4295 41.2603 68.522 41.2603 71.163C41.2603 74.3631 43.8586 76.9615 47.0587 76.9615C49.4601 76.9615 51.5207 75.5026 52.4046 73.4207H55.6899"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M59.4809 63.0058C59.4809 65.8012 57.2127 68.0695 54.4173 68.0695H54.2149C51.4195 68.0695 49.1512 65.8012 49.1512 63.0058V62.4094"
                                stroke="#222222"
                                stroke-miterlimit="10"
                            ></path>

                            <animateTransform
                                attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="360 0 0"
                                to="0 0 0"
                                dur="0.5min"
                                additive="sum"
                                repeatCount="indefinite"
                            />
                        </svg>

                        <div class="col-span-4 col-start-3 text-xs md:text-base">
                            Используем высокие технологии, собственные IT разработки и
                            искусственный интеллект.
                        </div>
                    </div>
                </a>
            </div>
            <img
                src="@/assets/img/index/hero.webp"
                class="relative mx-auto lg:absolute xl:-right-16 lg:-right-28 -z-10 h-[350px] md:h-[500px] w-[320px] md:w-2/3 b-0 lg:w-auto object-cover object-top lg:h-[122%] xl:h-[122%] lg:-bottom-[145px]"
                alt=""
            />
        </Container>
    </section>
</template>
<script>
import {useClinics} from "../../store/clinics";
import {useSlots} from "../../store/slots";
import HoursList from "@/components/UI/HoursList.vue";

export default {
    setup() {
        const route = useRoute();
        const clinicsStore = useClinics();
        const slotsStore = useSlots();
        let version = "a";
        if (route.fullPath.indexOf("version=b") !== -1) {
            version = "b";
        }

        return {
            version,
            slotsStore,
            clinicsStore,
            clinic: computed(() => clinicsStore.getClinicById(clinicsStore.clinicId)),
        };
    },
    methods: {
        goHome() {
            window.location = window.location;
        },
    },
    components: {
        HoursList,
    },
};
</script>
<style lang=""></style>
